import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../../components/context/LoadingContext"
import imageLetsTransform from "../../images/contactus/BannerLetsTransform.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"

const LetsTransform = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
      {windowWidth >= 1025 ? (
        <div className={"paddgingLetsTransform  text-left bigdesktop:pl-32"}>
          <div className="flex mt-20 contentApply">
            <div
              className={
                windowWidth < 1920
                  ? "mt-8 sizeTitleTransform"
                  : "mt-8 sizeTitleTransformBD"
              }
            >
              <div className="eventRightTimeLineMobile">
                <p className="titleOurServices">
                  {i18n.t("pageContactUs.LetsTransformTitle")}
                </p>
              </div>

              <p className="textExclusiveProductsTransform">
                {i18n.t("pageContactUs.LetsTransformText")}
              </p>
            </div>
            <div className="mt-4">
              <img className="sizeImageTransform" src={imageLetsTransform} />
              <div className="flex w-full justify-end ">
                <img className="pr-40" src={xElement} alt="xElement" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-32 p-6 text-left">
          <div className="mt-20">
            <div className="">
              <div className="mt-10">
                <img src={imageLetsTransform} alt="...." className="" />
                <div className="flex w-full justify-center ">
                  <img className="w-1/3" src={xElement} alt="xElement" />
                </div>
              </div>
              <div className="eventRightTimeLineMobile my-10 desktop:my-0">
                <p className="titleOurServices">
                  {i18n.t("pageContactUs.LetsTransformTitle")}
                </p>
              </div>

              <p className="text_our_experienceMobile mt-4">
                {i18n.t("pageContactUs.LetsTransformText")}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LetsTransform
