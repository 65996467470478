import React from "react"
import ImageBanner from "../images/contactus/BannerContactUs.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerXMmediaPodcast from "../components/xMmediaPodcast/BannerXMmediaPodcast"
import TextComponent from "../components/contactUs/TextBanner"
import LetsTransform from "../components/contactUs/LetsTransform"
import ContactForm from "../components/contactUs/ContactForm"
import ScheduleCall from "../components/contactUs/ScheduleCall"
import FollowUs from "../components/contactUs/FollowUs"
import { withNamespaces } from "react-i18next"

const ContactUs = () => {
  return (
    <Layout>
      <SEO
        title={"Contact Us | Exsis Digital Angels"}
        description={"Contact Us | Specialists in Tech Talent"}
      />
      <BannerXMmediaPodcast image={ImageBanner} TextComponent={TextComponent} />
      <LetsTransform />
      <ContactForm />
      <ScheduleCall />
      <FollowUs />
    </Layout>
  )
}

export default withNamespaces()(ContactUs)
